var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.status >= _vm.statuses.BROADCAST)?_c('section',[_c('div',{staticClass:"form-item d-table"},[_c('div',{staticClass:"d-table--cell align-vertical"},[_c('div',{staticClass:"m-r-15",class:{
          'i-check': _vm.status >= _vm.statuses.CONTRACT,
          green: _vm.status >= _vm.statuses.CONTRACT,
          'loader-ring':
            _vm.status == _vm.statuses.SEND ||
            _vm.status == _vm.statuses.OFFER ||
            _vm.status == _vm.statuses.FEEDBACK ||
            _vm.status == _vm.statuses.TX_LIABILITY,
          'i-stop': _vm.status < _vm.statuses.SEND
        }})]),_c('div',{staticClass:"d-table--cell align-vertical"},[_vm._v(" "+_vm._s(_vm.$t("steps.contract"))+" "),(_vm.status >= _vm.statuses.CONTRACT)?_c('a',{attrs:{"href":_vm.$filters.urlChainExplorer(_vm.liability),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("steps.view_contract"))+" ")]):_vm._e()])]),_c('div',{staticClass:"form-item d-table",class:{ disabled: _vm.status < _vm.statuses.CONTRACT }},[_c('div',{staticClass:"d-table--cell align-vertical"},[_c('div',{staticClass:"m-r-15",class:{
          'i-check': _vm.status == _vm.statuses.RESULT,
          green: _vm.status == _vm.statuses.RESULT,
          'loader-ring':
            _vm.status == _vm.statuses.CONTRACT ||
            _vm.status == _vm.statuses.REPORT ||
            _vm.status == _vm.statuses.TX_FINALIZATION,
          'i-stop': _vm.status < _vm.statuses.CONTRACT
        }})]),_c('div',{staticClass:"d-table--cell align-vertical"},[_vm._v(_vm._s(_vm.$t("steps.executed")))])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
import Page from "./components/Page";
import Info from "./components/Info";

export default {
  path: "/cross-chain-transfer",
  component: Page,
  children: [
    {
      path: "",
      name: "cross-chain-transfer",
      component: Info
    }
  ]
};

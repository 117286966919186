var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.ready)?_c('div',[(_vm.$robonomics.account)?_c('section',[_c('div',{staticClass:"input-size--md"},[(_vm.isRequest)?_c('RButton',{attrs:{"fullWidth":"","color":"green"}},[_vm._v(" "+_vm._s(_vm.$t("sensors.requested"))+" ")]):_c('RButton',{attrs:{"fullWidth":""},nativeOn:{"click":function($event){return _vm.sendMsgDemand($event)}}},[_vm._v(" "+_vm._s(_vm.$t("sensors.isRequest"))+" ")])],1)]):_vm._e(),(_vm.log.length > 0)?_c('RCard',[_c('table',{staticClass:"container-full table-hover"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.$t("sensors.table.model")))]),_c('th',[_vm._v(_vm._s(_vm.$t("sensors.table.sender")))]),_c('th',[_vm._v(_vm._s(_vm.$t("sensors.table.token")))]),_c('th',[_vm._v(_vm._s(_vm.$t("sensors.table.view")))])])]),_c('tbody',_vm._l((_vm.log),function(item,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(key + 1))]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.getTypeByModel(item.model)))]),_vm._v(" "),_c('RIpfsExplorer',{attrs:{"hash":item.model}})],1),_c('td',[_c('RChainExplorer',{attrs:{"address":item.sender}})],1),_c('td',[(item.cost > 0)?[_c('RChainExplorer',{attrs:{"address":item.token,"category":"token"}}),_vm._v("/ "),_c('b',[_vm._v(_vm._s(item.cost))])]:[_c('b',[_vm._v(_vm._s(_vm.$t("sensors.table.free")))])]],2),_c('td',[(item.cost > 0)?_c('router-link',{attrs:{"to":{
                  name: 'sensor-cost',
                  params: {
                    lighthouse: _vm.lighthouse,
                    model: item.model,
                    agent: item.sender,
                    token: item.token,
                    cost: item.cost
                  }
                }}},[_vm._v(_vm._s(_vm.$t("sensors.table.view")))]):_c('router-link',{attrs:{"to":{
                  name: 'sensor',
                  params: {
                    lighthouse: _vm.lighthouse,
                    model: item.model,
                    agent: item.sender
                  }
                }}},[_vm._v(_vm._s(_vm.$t("sensors.table.view")))])],1)])}),0)])]):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <!-- <div>
      Origin Chain
      <select>
        <option>Rococo</option>
      </select>
    </div>
    <div>
      Destination Chain
      <select>
        <option>Robonomics</option>
      </select>
    </div> -->
    <div>
      Destination Account
      <select v-model="account">
        <option
          v-for="(account, k) in accounts"
          :key="k"
          :value="account.address"
        >
          {{ account.meta.name }} - {{ account.address.substr(0, 6) }}...{{
            account.address.substr(-6)
          }}
        </option>
      </select>
    </div>
    <div>
      Amount
      <input v-model="amount" />
    </div>
    <div>xrt: {{ xrt }}</div>
    <div>asset: {{ asset }}</div>
    <div>roc: {{ roc }}</div>
    <div>
      <button @click="send">send</button>
    </div>
    <div v-if="result">
      {{ result }}
    </div>
  </div>
</template>

<script>
import { u8aToHex } from "@polkadot/util";
import { getInstance } from "../../../utils/substrate";
import filters from "../../../utils/filters";

export default {
  data() {
    return {
      robonomics: null,
      rococo: null,
      account: null,
      accounts: [],
      amount: 0,
      result: null,
      xrt: "",
      roc: "",
      asset: "",
      unsubscribeAsset: null,
      unsubscribeXrt: null,
      unsubscribeRoc: null
    };
  },
  async created() {
    this.robonomics = await getInstance("robonomics-roc", false);
    this.rococo = await getInstance("rococo", false);
    this.robonomics.accountManager.onReady((e) => {
      if (e) {
        console.log(e.message);
        return;
      }
      this.loadAccounts();
      this.robonomics.accountManager.onChange((account) => {
        this.account = account.address;
      });
    });
  },
  watch: {
    account() {
      this.balances();
    }
  },
  methods: {
    async balances() {
      if (this.unsubscribeXrt) {
        this.unsubscribeXrt();
      }
      if (this.unsubscribeRoc) {
        this.unsubscribeRoc();
      }
      if (this.unsubscribeAsset) {
        this.unsubscribeRoc();
      }
      this.unsubscribeAsset = await this.robonomics.api.query.assets.account(
        4294967295,
        this.account,
        (asset) => {
          if (!asset.isEmpty) {
            this.asset = filters.fromUnit(asset.value.balance, 12);
          } else {
            this.asset = 0;
          }
        }
      );
      this.unsubscribeXrt = await this.robonomics.account.listenBalance(
        this.account,
        (r) => {
          this.xrt = filters.fromUnit(r, 9);
        }
      );
      this.unsubscribeRoc = await this.rococo.account.listenBalance(
        this.account,
        (r) => {
          this.roc = filters.fromUnit(r, 12);
        }
      );
    },
    async loadAccounts() {
      this.accounts = this.robonomics.accountManager.getAccounts();
      if (this.accounts.length) {
        this.account = this.accounts[0].address;
      }
    },
    async send() {
      this.result = null;
      const instance = this.rococo;
      const account = await instance.accountManager.selectAccountByAddress(
        this.account
      );
      const dest = {
        V1: {
          parents: 0,
          interior: {
            X1: {
              Parachain: 2048
            }
          }
        }
      };
      const beneficiary = {
        V1: {
          parents: 0,
          interior: {
            X1: {
              AccountId32: {
                network: "Any",
                id: u8aToHex(account.publicKey)
              }
            }
          }
        }
      };
      const assets = {
        V1: [
          {
            id: {
              Concrete: {
                parents: 0,
                interior: "Here"
              }
            },
            fun: {
              Fungible: filters.toUnit(this.amount, 12)
            }
          }
        ]
      };
      const feeAssetItem = 0;
      const tx = instance.api.tx.xcmPallet.reserveTransferAssets(
        dest,
        beneficiary,
        assets,
        feeAssetItem
      );
      this.result = await instance.accountManager.signAndSend(tx);
      console.log(this.result);
    }
  }
};
</script>

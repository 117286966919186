<template>
  <Page>
    <Lighthouse />
  </Page>
</template>

<script>
import Page from "@/components/layout/Page";
import Lighthouse from "../components/lighthouse/Lighthouse";

export default {
  components: {
    Page,
    Lighthouse
  }
};
</script>

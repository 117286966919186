var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',[(_vm.ready)?_c('section',{staticClass:"section-light section-centered"},[_c('h2',[_vm._v(_vm._s(_vm.$t("passport.title")))]),(_vm.passport)?[_c('Passport',{attrs:{"address":_vm.passport}})]:(_vm.$robonomics.account)?[_c('Form',{ref:"form",on:{"onSubmit":_vm.handleSubmit}}),(!_vm.roboCycle.offer)?_c('Request',{ref:"request",attrs:{"model":_vm.model,"token":_vm.tokenAddress,"validator":_vm.validator},on:{"submit":function($event){return _vm.$refs.form.submit()},"on-response":_vm.handleResponse}}):[_c('section',[_c('div',{staticClass:"form-section-title"},[_vm._v(_vm._s(_vm.$t("passport.subtitle3")))]),_c('Response',{attrs:{"sender":_vm.roboCycle.offer.sender,"objective":_vm.roboCycle.offer.objective,"address":_vm.roboCycle.offer.token,"from":_vm.$robonomics.account.address,"to":_vm.$robonomics.factory.address,"cost":_vm.roboCycle.offer.cost,"initDetails":Number(_vm.cost) > Number(_vm.myAllowance)}}),(
              _vm.roboCycle.demand === null &&
              Number(_vm.cost) > 0 &&
              Number(_vm.myAllowance) < Number(_vm.roboCycle.offer.cost)
            )?_c('section',{staticClass:"section-light"},[_c('div',[_c('b',[_vm._v(_vm._s(_vm.$t("passport.reqApprove")))])]),_c('Approve',{attrs:{"address":_vm.roboCycle.offer.token,"from":_vm.$robonomics.account.address,"to":_vm.$robonomics.factory.address,"initAmountWei":_vm.cost}})],1):_vm._e(),(_vm.roboCycle.demand)?_c('Steps',{attrs:{"status":_vm.roboCycle.status,"liability":_vm.roboCycle.liability}}):_vm._e(),(
              _vm.roboCycle.demand === null || _vm.roboCycle.status != _vm.statuses.RESULT
            )?_c('section',{class:{
              disabled:
                (Number(_vm.cost) > 0 && Number(_vm.myAllowance) < Number(_vm.cost)) ||
                (_vm.roboCycle.demand && _vm.roboCycle.status != _vm.statuses.EMPTY)
            }},[_c('RButton',{attrs:{"fullWidth":"","size":"big","disabled":_vm.roboCycle.status > _vm.statuses.EMPTY &&
                _vm.roboCycle.status < _vm.statuses.RESULT},on:{"click":_vm.sendMsgDemand}},[(
                  _vm.roboCycle.status > _vm.statuses.EMPTY &&
                  _vm.roboCycle.status < _vm.statuses.RESULT
                )?_c('div',{staticClass:"loader-ring"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("passport.order"))+" ")])],1):_vm._e(),(_vm.roboCycle.demand && _vm.roboCycle.status == _vm.statuses.RESULT)?_c('div',[_c('router-link',{staticClass:"container-full btn-big btn-green",attrs:{"to":{
                name: 'passport-view',
                params: { passport: _vm.roboCycle.liability }
              }}},[_vm._v(_vm._s(_vm.$t("passport.link")))])],1):_vm._e()],1)]]:_vm._e()],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
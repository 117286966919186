<template>
  <Page>
    <section>
      <div>
        <section v-if="error" class="section-light">
          <b>{{ error }}</b>
        </section>
        <template v-else>
          <section class="section-light">
            <router-view v-if="ready"></router-view>
            <div v-else class="t-align--center">
              <b class="align-vertical t-style_uppercase">Load</b><RLoader />
            </div>
          </section>
        </template>
      </div>
    </section>
  </Page>
</template>

<script>
import Page from "@/components/layout/Page";
import { getInstance } from "@/utils/substrate";

export default {
  components: {
    Page
  },
  data() {
    return {
      ready: false,
      error: ""
    };
  },
  async created() {
    try {
      const robonomics = await getInstance("robonomics-roc", false);
      await getInstance("rococo", false);
      robonomics.accountManager.onReady((e) => {
        if (e) {
          console.log(e.message);
          return;
        }
        this.ready = true;
      });
    } catch (error) {
      this.error = error.message;
    }
  },
  methods: {
    upBurn(r) {
      console.log(r);
    }
  }
};
</script>

import config from "@/config/substrate";
import keyring from "@polkadot/ui-keyring";
import {
  AccountManagerUi as AccountManager,
  Robonomics
} from "robonomics-interface";
// import config from "@/config/substrate.dev";

export async function initPlugin(isDevelopment = false) {
  await AccountManager.initPlugin(keyring, {
    isDevelopment
  });
}

export function createInstance(name = "robonomics") {
  const robonomics = new Robonomics({ ...config[name], runImmediate: true });
  const am = new AccountManager(keyring);
  robonomics.setAccountManager(am);
  return robonomics;
}

export function getInstance(
  name = "robonomics",
  isWaitingAccount = true,
  providerListener = undefined
) {
  return new Promise((resolve, reject) => {
    let robonomics = null;
    try {
      robonomics = Robonomics.getInstance(name);
    } catch (_) {
      robonomics = createInstance(name);
    }

    if (providerListener) {
      robonomics.provider.on("error", (e) => {
        providerListener("error", e);
      });
      robonomics.provider.on("connected", () => {
        providerListener("connected");
      });
      robonomics.provider.on("disconnected", () => {
        providerListener("disconnected");
      });
    }

    robonomics.onReady(() => {
      if (isWaitingAccount) {
        robonomics.accountManager.onReady((e) => {
          if (e) {
            reject(e, robonomics);
          }
          resolve(robonomics);
        });
      } else {
        resolve(robonomics);
      }
    });
  });
}

<template>
  <Page> Balance </Page>
</template>

<script>
import Page from "@/components/layout/Page";
import { Robonomics } from "../utils/robonomics-substrate";

export default {
  components: {
    Page
  },
  data() {
    return {
      robonomics: null
    };
  },
  async created() {
    this.robonomics = Robonomics.getInstance("robonomics");

    const accs = {
      xrt: "4GQ7hXqYQ9gqUp3eByTvr5du8ru7ZjiS3jJafSPPFgMhTDv1",
      ens: "4FiueWs4Q223iyvcYmEUQAJexNXZRQnvkML1sqWxZ8QXuCW1",
      ppp: "4EQpJGcFasiWXfBmfZKxomgWPhZvg2YX2wQhcnUJUyev2ccQ"
    };

    for (const key in accs) {
      const asd = await this.robonomics.api.derive.balances.all(accs[key]);
      console.log(asd);

      const b = {
        total: asd.freeBalance.add(asd.reservedBalance).toString(),
        transferrable: asd.availableBalance.toString(),
        locked: asd.lockedBalance.toString(),
        available: asd.availableBalance.toString()
      };

      console.log(key, b);
    }

    const safdas = await this.robonomics.account.getBalance(accs["ppp"]);
    console.log(safdas.toJSON());

    //     free: 3,266,689,965,520
    //     reserved: 0
    //     miscFrozen: 2,945,250,000,000
    //     feeFrozen: 300,000,000,000

    //       {
    //   nonce: 1
    //   consumers: 2
    //   providers: 1
    //   sufficients: 0
    //   data: {
    //     free: 3,266,689,965,520
    //     reserved: 0
    //     miscFrozen: 2,945,250,000,000
    //     feeFrozen: 300,000,000,000
    //   }
    // }
  }
};
</script>

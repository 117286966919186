export default {
  id: "passport",
  en: {
    title: "Public blockchain stamp",
    subtitle1: "Publisher information",
    subtitle2: "Collected information",
    subtitle3: "Public blockchain stamp",
    priceTitle: "Cost of a blockchain stamp",
    details: "Show more details",
    detailsLess: "Show less details",
    success: "Your blockchain stamp",
    link: "view blockchain stamp",
    agent: "Agent",
    objective: "Objective",
    token: "Token",
    requestPrice: "Request service",
    liability: "Liability",
    group1: "Info",
    info: "Additional Information",
    meta: "Information file",
    images: "Photos",
    email: "E-mail",
    order: "order",
    error: "Check if data correct, please.",
    informations: "Additional Information",
    metaField: "Attach information file (json)",
    imagesField: "Attach photos, if any (jpg, png)",
    emailField: "E-mail",
    dragFile: "Drag & Drop your file or Browse",
    dragImages: "Drag & Drop your image files or Browse",
    view: "view",
    viewImages: "view images",
    hideImages: "hide images",
    loading: "Loading",
    openIpfs: "Open IPFS",
    reqApprove:
      "You need to approve an additional amount to purchase a blockchain stamp"
  },
  ru: {
    title: "Публичный блокчейн-штамп",
    subtitle1: "Информация об отправителе",
    subtitle2: "Собранная информация",
    subtitle3: "Заказ блокчейн-штампа",
    priceTitle: "Стоимость блокчейн-штампа составит",
    details: "Показать больше деталей",
    detailsLess: "Показать меньше деталей",
    success: "Ваш блокчейн-штамп",
    link: "Перейти на страницу блокчейн-штампа",
    agent: "Агент",
    objective: "Задача",
    token: "Токен",
    requestPrice: "Запросить услугу",
    liability: "Контракт обязательства",
    info: "Дополнительная информация",
    meta: "Файл с информацией",
    images: "Фотографии",
    email: "E-mail",
    order: "Заказать блокчейн-штамп",
    error: "Проверьте пожалуйста правильность данных.",
    informations: "Дополнительная информация",
    metaField: "Прикрепите файл с информацией (json)",
    imagesField: "Прикрепите фотографии, если есть (jpg, png)",
    emailField: "E-mail",
    dragFile: "Drag & Drop your file or Browse",
    dragImages: "Drag & Drop your image files or Browse",
    view: "просмотр",
    viewImages: "показать фото",
    hideImages: "скрыть фото",
    loading: "Загрузка",
    openIpfs: "Открыть в IPFS",
    reqApprove:
      "Вам нужно одобрить дополнительную сумму для покупки блокчейн-штампа"
  }
};

import artShop from "./art-shop";
import auction from "./auction";
import bigBag from "./bigbag";
import blockchainStamp from "./blockchain-stamp";
import bonusForPioneer from "./bonus-for-pioneer";
import crossChainTransfer from "./cross-chain-transfer";
import datalog from "./datalog";
import decoded from "./decoded";
import devices from "./devices";
import droneFlightReport from "./drone-flight-report";
import droneRegistration from "./drone-registration";
// import exodus from "./exodus";
import fuji from "./fuji";
import gakaChu from "./gaka-chu";
import ipciValidator from "./ipci-validator";
import issuing from "./issuing";
import nft from "./nft";
import offsetting from "./offsetting";
import parachain from "./parachain";
import rws from "./rws";
import sensor from "./sensor";
import sensorsMap from "./sensors-map";
import sensorsNetwork from "./sensors-network";
import xrtForVote from "./xrt-for-vote";

export default {
  parachain,
  decoded,
  devices,
  fuji,
  sensorsMap,
  blockchainStamp,
  sensorsNetwork,
  sensor,
  gakaChu,
  offsetting,
  issuing,
  droneRegistration,
  droneFlightReport,
  rws,
  datalog,
  //exodus,
  xrtForVote,
  bigBag,
  auction,
  artShop,
  ipciValidator,
  nft,
  bonusForPioneer,
  crossChainTransfer
};
